@font-face {
  font-family: 'FoundersGrotesk-Regular';
  src: url('../resources/fonts/FoundersGrotesk/FoundersGrotesk-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FoundersGrotesk-Medium';
  src: url('../resources/fonts/FoundersGrotesk/FoundersGrotesk-Medium.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'FoundersGrotesk-Bold';
  src: url('../resources/fonts/FoundersGrotesk/FoundersGrotesk-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
}
strong, b {
  font-weight: bold;
}