@font-face {
  font-family: 'din-pro-regular';
  src: url('../resources/fonts/din-pro/DIN-Pro.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'din-pro-regular';
  src: url('../resources/fonts/din-pro/DIN-Pro-Bold.otf') format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'din-pro-bold';
  src: url('../resources/fonts/din-pro/DIN-Pro-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
strong, b {
  font-weight: bold;
}