@font-face {
  font-family: 'din2014-regular';
  src: url('../resources/fonts/din2014/DIN2014-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'din2014-regular';
  src: url('../resources/fonts/din2014/DIN2014-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'din2014-regular';
  src: url('../resources/fonts/din2014/DIN2014-DemiBold.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'din2014-semi-bold';
  src: url('../resources/fonts/din2014/DIN2014-DemiBold.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'din2014-bold';
  src: url('../resources/fonts/din2014/DIN2014-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
strong, b {
  font-weight: bold;
}