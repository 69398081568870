@import "./styles/din2014.less";
@import "./styles/din-pro.less";
@import "./styles/roboto.less";
@import "./styles/nei_icons.less";
@import "./styles/foundersgrotesk.less";
@import "./styles/style.less";
@import "./styles/bootstrap.less";
@import "./styles/components/button.less";

:root {
  --color-navy-500: #112055;
  --color-navy-400: #345D6F;
  --color-navy-300: #678593;
  --color-navy-200: #99AEB7;
  --color-navy-100: #CCD6DB;

  --color-blue-800: #122055;
  --color-blue-700: #C0DDF3;
  --color-blue-600: #0077CD;
  --color-blue-500: #3C8ED5;
  --color-blue-400: #205098;
  --color-blue-300: #0056b3;
  --color-blue-200: #0077CD;
  --color-blue-100: #E6F1FA;

  --color-turkish-300: #0080A6;
  --color-turkish-200: #99CCDB;
  --color-turkish-100: #E6F2F6;

  --color-jade-300: #112055;
  --color-jade-200: #99CDC5;
  --color-jade-100: #CCE6E2;

  --color-red-500: #CE0019;

  --color-green-300: #85BE00;
  --color-green-200: #CEE7E3;
  --color-green-100: #E6F3F1;

  --color-purple-200: #5948AD;
  --color-purple-100: #6f42c1;

  --color-yellow: #ffc107;
  --color-cyan: #17a2b8;
  --color-white: #FFFFFF;

  --color-gray-800:  #221122;
  --color-gray-700: #E6EBED;
  --color-gray-600: #212121;
  --color-gray-500: #555960;
  --color-gray-400: #84878C;
  --color-gray-300: #EFEFEF;
  --color-gray-200: #E2E3E5;
  --color-gray-100: #FAFAFA;

  --color-slate-200: #DDDEDF;
  --color-slate-100: #EEEEEF;

  --color-light: #f8f9fa;
  --color-dark: #343a40;
  --color-black: #000;

  --color-neutral-on-background: #352C39;

  --font-family-primary: "FoundersGrotesk-Regular", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  --font-family-secondary: "din2014-regular",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif;

  // font weights
  --font-weight-bold: 700;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-demi: 600;
}