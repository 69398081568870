@font-face {
  font-family: din2014-regular;
  src: url("resources/fonts/DIN2014-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: din2014-regular;
  src: url("resources/fonts/DIN2014-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: din2014-regular;
  src: url("resources/fonts/DIN2014-DemiBold.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: din2014-semi-bold;
  src: url("resources/fonts/DIN2014-DemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: din2014-bold;
  src: url("resources/fonts/DIN2014-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: din-pro-regular;
  src: url("DIN-Pro.38d394c2.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: din-pro-regular;
  src: url("DIN-Pro-Bold.38d394c2.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: din-pro-bold;
  src: url("DIN-Pro-Bold.38d394c2.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: roboto;
  src: url("resources/fonts/Roboto-Regular.ttf");
  src: url("resources/fonts/Roboto-Regular.ttf#iefix") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: nei_icons;
  src: url("nei_icons.d86b645a.eot");
  src: url("nei_icons.d86b645a.eot#iefix") format("embedded-opentype"), url("resources/fonts/nei_icons.woff2") format("woff2"), url("resources/fonts/nei_icons.woff") format("woff"), url("resources/fonts/nei_icons.ttf") format("truetype"), url("resources/images/nei_icons.svg#nei_icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  speak: never;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  margin-left: .2em;
  margin-right: .2em;
  font-family: nei_icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.icon-fossil_fuel_free:before {
  content: "";
}

.icon-impact_thematic:before {
  content: "";
}

.icon-active_ownership:before {
  content: "";
}

.icon-esg_integration:before {
  content: "";
}

.icon-exclusionary_screens:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-heart:before {
  content: "";
}

.icon-heart-empty:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-star-empty:before {
  content: "";
}

.icon-star-half:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-picture:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-th-large:before {
  content: "";
}

.icon-th:before {
  content: "";
}

.icon-th-list:before {
  content: "";
}

.icon-ok:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-trash-empty:before {
  content: "";
}

.icon-doc:before {
  content: "";
}

.icon-folder-open:before {
  content: "";
}

.icon-folder:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-info-circled:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-retweet:before {
  content: "";
}

.icon-comment:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-attention:before {
  content: "";
}

.icon-attention-circled:before {
  content: "";
}

.icon-flag:before {
  content: "";
}

.icon-tags:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-eye-off:before {
  content: "";
}

.icon-eye:before {
  content: "";
}

.icon-lock-open:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-attach:before {
  content: "";
}

.icon-forward:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-pencil:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-print:before {
  content: "";
}

.icon-down-circled2:before {
  content: "";
}

.icon-up-circled2:before {
  content: "";
}

.icon-down-dir:before {
  content: "";
}

.icon-up-dir:before {
  content: "";
}

.icon-left-dir:before {
  content: "";
}

.icon-right-dir:before {
  content: "";
}

.icon-down-open:before {
  content: "";
}

.icon-left-open:before {
  content: "";
}

.icon-right-open:before {
  content: "";
}

.icon-up-open:before {
  content: "";
}

.icon-inbox:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-award:before {
  content: "";
}

.icon-to-start-alt:before {
  content: "";
}

.icon-fast-fw:before {
  content: "";
}

.icon-fast-bw:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-stop:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-to-end:before {
  content: "";
}

.icon-to-end-alt:before {
  content: "";
}

.icon-to-start:before {
  content: "";
}

.icon-text-height:before {
  content: "";
}

.icon-text-width:before {
  content: "";
}

.icon-align-left:before {
  content: "";
}

.icon-align-center:before {
  content: "";
}

.icon-align-right:before {
  content: "";
}

.icon-align-justify:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-indent-left:before {
  content: "";
}

.icon-indent-right:before {
  content: "";
}

.icon-cloud:before {
  content: "";
}

.icon-flash:before {
  content: "";
}

.icon-umbrella:before {
  content: "";
}

.icon-scissors:before {
  content: "";
}

.icon-chart-bar:before {
  content: "";
}

.icon-credit-card:before {
  content: "";
}

.icon-floppy:before {
  content: "";
}

.icon-megaphone:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-cog:before {
  content: "";
}

.icon-cog-alt:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-volume-off:before {
  content: "";
}

.icon-volume-down:before {
  content: "";
}

.icon-volume-up:before {
  content: "";
}

.icon-block:before {
  content: "";
}

.icon-resize-full:before {
  content: "";
}

.icon-resize-small:before {
  content: "";
}

.icon-resize-vertical:before {
  content: "";
}

.icon-resize-horizontal:before {
  content: "";
}

.icon-zoom-in:before {
  content: "";
}

.icon-zoom-out:before {
  content: "";
}

.icon-minus-circled:before {
  content: "";
}

.icon-plus-circled:before {
  content: "";
}

.icon-cancel:before {
  content: "";
}

.icon-cancel-circled:before {
  content: "";
}

.icon-cancel-circled2:before {
  content: "";
}

.icon-ok-circled:before {
  content: "";
}

.icon-ok-circled2:before {
  content: "";
}

.icon-truck:before {
  content: "";
}

.icon-list-alt:before {
  content: "";
}

.icon-adjust:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-attention-1:before {
  content: "";
}

.icon-cw:before {
  content: "";
}

.icon-ccw:before {
  content: "";
}

.icon-arrows-cw:before {
  content: "";
}

.icon-heart-1:before {
  content: "";
}

.icon-heart-filled:before {
  content: "";
}

.icon-star-1:before {
  content: "";
}

.icon-star-filled:before {
  content: "";
}

.icon-off:before {
  content: "";
}

.icon-leaf:before {
  content: "";
}

.icon-flight:before {
  content: "";
}

.icon-down-big:before {
  content: "";
}

.icon-left-big:before {
  content: "";
}

.icon-right-big:before {
  content: "";
}

.icon-up-big:before {
  content: "";
}

.icon-right-hand:before {
  content: "";
}

.icon-left-hand:before {
  content: "";
}

.icon-up-hand:before {
  content: "";
}

.icon-down-hand:before {
  content: "";
}

.icon-basket:before {
  content: "";
}

.icon-login:before {
  content: "";
}

.icon-logout:before {
  content: "";
}

.icon-headphones:before {
  content: "";
}

.icon-thumbs-up:before {
  content: "";
}

.icon-thumbs-down:before {
  content: "";
}

.icon-location:before {
  content: "";
}

.icon-wrench:before {
  content: "";
}

.icon-help-circled:before {
  content: "";
}

.icon-briefcase:before {
  content: "";
}

.icon-road:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-barcode:before {
  content: "";
}

.icon-down-bold:before {
  content: "";
}

.icon-left-bold:before {
  content: "";
}

.icon-right-bold:before {
  content: "";
}

.icon-up-bold:before {
  content: "";
}

.icon-ccw-1:before {
  content: "";
}

.icon-cw-1:before {
  content: "";
}

.icon-arrows-ccw:before {
  content: "";
}

.icon-level-down:before {
  content: "";
}

.icon-level-up:before {
  content: "";
}

.icon-shuffle:before {
  content: "";
}

.icon-loop:before {
  content: "";
}

.icon-user-1:before {
  content: "";
}

.icon-users-1:before {
  content: "";
}

.icon-user-add:before {
  content: "";
}

.icon-user-outline:before {
  content: "";
}

.icon-user-2:before {
  content: "";
}

.icon-users-outline:before {
  content: "";
}

.icon-users-2:before {
  content: "";
}

.icon-user-add-outline:before {
  content: "";
}

.icon-user-add-1:before {
  content: "";
}

.icon-user-delete-outline:before {
  content: "";
}

.icon-user-delete:before {
  content: "";
}

.icon-spin3:before {
  content: "";
}

.icon-spin4:before {
  content: "";
}

.icon-spin6:before {
  content: "";
}

.icon-crown:before {
  content: "";
}

.icon-crown-plus:before {
  content: "";
}

.icon-crown-minus:before {
  content: "";
}

.icon-marquee:before {
  content: "";
}

.icon-result:before {
  content: "";
}

.icon-nei_chevron:before {
  content: "";
}

.icon-quote_mark:before {
  content: "";
}

.icon-ri_icon_exclusionary_screens-converted:before {
  content: "";
}

.icon-ri_icon_fossil_fuel_-free-converted:before {
  content: "";
}

.icon-ri_icon_impact_thematic-converted:before {
  content: "";
}

.icon-ri_icon_active_ownership-converted:before {
  content: "";
}

.icon-ri_icon_esg_integration-converted:before {
  content: "";
}

.icon-move:before {
  content: "";
}

.icon-link-ext:before {
  content: "";
}

.icon-check-empty:before {
  content: "";
}

.icon-bookmark-empty:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-github-circled:before {
  content: "";
}

.icon-hdd:before {
  content: "";
}

.icon-left-circled:before {
  content: "";
}

.icon-right-circled:before {
  content: "";
}

.icon-up-circled:before {
  content: "";
}

.icon-down-circled:before {
  content: "";
}

.icon-tasks:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-resize-full-alt:before {
  content: "";
}

.icon-beaker:before {
  content: "";
}

.icon-docs:before {
  content: "";
}

.icon-blank:before {
  content: "";
}

.icon-list-bullet:before {
  content: "";
}

.icon-list-numbered:before {
  content: "";
}

.icon-strike:before {
  content: "";
}

.icon-underline:before {
  content: "";
}

.icon-table:before {
  content: "";
}

.icon-pinterest-circled:before {
  content: "";
}

.icon-pinterest-squared:before {
  content: "";
}

.icon-money:before {
  content: "";
}

.icon-columns:before {
  content: "";
}

.icon-mail-alt:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-gauge:before {
  content: "";
}

.icon-comment-empty:before {
  content: "";
}

.icon-chat-empty:before {
  content: "";
}

.icon-lightbulb:before {
  content: "";
}

.icon-suitcase:before {
  content: "";
}

.icon-bell-alt:before {
  content: "";
}

.icon-doc-text:before {
  content: "";
}

.icon-building:before {
  content: "";
}

.icon-hospital:before {
  content: "";
}

.icon-ambulance:before {
  content: "";
}

.icon-medkit:before {
  content: "";
}

.icon-fighter-jet:before {
  content: "";
}

.icon-h-sigh:before {
  content: "";
}

.icon-plus-squared:before {
  content: "";
}

.icon-angle-double-left:before {
  content: "";
}

.icon-angle-double-right:before {
  content: "";
}

.icon-angle-double-up:before {
  content: "";
}

.icon-angle-double-down:before {
  content: "";
}

.icon-angle-left:before {
  content: "";
}

.icon-angle-right:before {
  content: "";
}

.icon-angle-up:before {
  content: "";
}

.icon-angle-down:before {
  content: "";
}

.icon-desktop:before {
  content: "";
}

.icon-laptop:before {
  content: "";
}

.icon-tablet:before {
  content: "";
}

.icon-mobile:before {
  content: "";
}

.icon-circle-empty:before {
  content: "";
}

.icon-quote-left:before {
  content: "";
}

.icon-spinner:before {
  content: "";
}

.icon-circle:before {
  content: "";
}

.icon-reply:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-folder-empty:before {
  content: "";
}

.icon-folder-open-empty:before {
  content: "";
}

.icon-smile:before {
  content: "";
}

.icon-frown:before {
  content: "";
}

.icon-meh:before {
  content: "";
}

.icon-keyboard:before {
  content: "";
}

.icon-flag-empty:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-reply-all:before {
  content: "";
}

.icon-star-half-alt:before {
  content: "";
}

.icon-direction:before {
  content: "";
}

.icon-crop:before {
  content: "";
}

.icon-unlink:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-attention-alt:before {
  content: "";
}

.icon-superscript:before {
  content: "";
}

.icon-subscript:before {
  content: "";
}

.icon-mic:before {
  content: "";
}

.icon-mute:before {
  content: "";
}

.icon-calendar-empty:before {
  content: "";
}

.icon-angle-circled-left:before {
  content: "";
}

.icon-angle-circled-right:before {
  content: "";
}

.icon-angle-circled-up:before {
  content: "";
}

.icon-angle-circled-down:before {
  content: "";
}

.icon-html5:before {
  content: "";
}

.icon-lock-open-alt:before {
  content: "";
}

.icon-rss-squared:before {
  content: "";
}

.icon-ticket:before {
  content: "";
}

.icon-minus-squared:before {
  content: "";
}

.icon-minus-squared-alt:before {
  content: "";
}

.icon-ok-squared:before {
  content: "";
}

.icon-pencil-squared:before {
  content: "";
}

.icon-pound:before {
  content: "";
}

.icon-dollar:before {
  content: "";
}

.icon-rupee:before {
  content: "";
}

.icon-yen:before {
  content: "";
}

.icon-rouble:before {
  content: "";
}

.icon-bitcoin:before {
  content: "";
}

.icon-doc-inv:before {
  content: "";
}

.icon-doc-text-inv:before {
  content: "";
}

.icon-dropbox:before {
  content: "";
}

.icon-tumblr:before {
  content: "";
}

.icon-tumblr-squared:before {
  content: "";
}

.icon-down:before {
  content: "";
}

.icon-up:before {
  content: "";
}

.icon-left:before {
  content: "";
}

.icon-right:before {
  content: "";
}

.icon-apple:before {
  content: "";
}

.icon-android:before {
  content: "";
}

.icon-skype:before {
  content: "";
}

.icon-sun:before {
  content: "";
}

.icon-moon:before {
  content: "";
}

.icon-right-circled2:before {
  content: "";
}

.icon-left-circled2:before {
  content: "";
}

.icon-dot-circled:before {
  content: "";
}

.icon-wheelchair:before {
  content: "";
}

.icon-vimeo-squared:before {
  content: "";
}

.icon-plus-squared-alt:before {
  content: "";
}

.icon-space-shuttle:before {
  content: "";
}

.icon-slack:before {
  content: "";
}

.icon-bank:before {
  content: "";
}

.icon-google:before {
  content: "";
}

.icon-reddit:before {
  content: "";
}

.icon-digg:before {
  content: "";
}

.icon-drupal:before {
  content: "";
}

.icon-language:before {
  content: "";
}

.icon-building-filled:before {
  content: "";
}

.icon-cab:before {
  content: "";
}

.icon-taxi:before {
  content: "";
}

.icon-file-pdf:before {
  content: "";
}

.icon-file-word:before {
  content: "";
}

.icon-file-excel:before {
  content: "";
}

.icon-file-powerpoint:before {
  content: "";
}

.icon-file-image:before {
  content: "";
}

.icon-jsfiddle:before {
  content: "";
}

.icon-rebel:before {
  content: "";
}

.icon-empire:before {
  content: "";
}

.icon-git-squared:before {
  content: "";
}

.icon-git:before {
  content: "";
}

.icon-paper-plane:before {
  content: "";
}

.icon-paper-plane-empty:before {
  content: "";
}

.icon-circle-thin:before {
  content: "";
}

.icon-header:before {
  content: "";
}

.icon-paragraph:before {
  content: "";
}

.icon-sliders:before {
  content: "";
}

.icon-newspaper:before {
  content: "";
}

.icon-wifi:before {
  content: "";
}

.icon-calc:before {
  content: "";
}

.icon-paypal:before {
  content: "";
}

.icon-cc-visa:before {
  content: "";
}

.icon-cc-mastercard:before {
  content: "";
}

.icon-cc-discover:before {
  content: "";
}

.icon-cc-amex:before {
  content: "";
}

.icon-cc-paypal:before {
  content: "";
}

.icon-cc-stripe:before {
  content: "";
}

.icon-bell-off:before {
  content: "";
}

.icon-bell-off-empty:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-copyright:before {
  content: "";
}

.icon-at:before {
  content: "";
}

.icon-eyedropper:before {
  content: "";
}

.icon-chart-area:before {
  content: "";
}

.icon-chart-pie:before {
  content: "";
}

.icon-chart-line:before {
  content: "";
}

.icon-toggle-off:before {
  content: "";
}

.icon-toggle-on:before {
  content: "";
}

.icon-bicycle:before {
  content: "";
}

.icon-bus:before {
  content: "";
}

.icon-cart-plus:before {
  content: "";
}

.icon-cart-arrow-down:before {
  content: "";
}

.icon-ship:before {
  content: "";
}

.icon-motorcycle:before {
  content: "";
}

.icon-neuter:before {
  content: "";
}

.icon-facebook-official:before {
  content: "";
}

.icon-pinterest:before {
  content: "";
}

.icon-whatsapp:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
}

.icon-user-times:before {
  content: "";
}

.icon-bed:before {
  content: "";
}

.icon-train:before {
  content: "";
}

.icon-subway:before {
  content: "";
}

.icon-battery-4:before {
  content: "";
}

.icon-battery-3:before {
  content: "";
}

.icon-battery-2:before {
  content: "";
}

.icon-battery-1:before {
  content: "";
}

.icon-i-cursor:before {
  content: "";
}

.icon-sticky-note:before {
  content: "";
}

.icon-sticky-note-o:before {
  content: "";
}

.icon-cc-jcb:before {
  content: "";
}

.icon-cc-diners-club:before {
  content: "";
}

.icon-clone:before {
  content: "";
}

.icon-hand-pointer-o:before {
  content: "";
}

.icon-chrome:before {
  content: "";
}

.icon-firefox:before {
  content: "";
}

.icon-internet-explorer:before {
  content: "";
}

.icon-calendar-times-o:before {
  content: "";
}

.icon-calendar-check-o:before {
  content: "";
}

.icon-map-o:before {
  content: "";
}

.icon-map:before {
  content: "";
}

.icon-commenting:before {
  content: "";
}

.icon-commenting-o:before {
  content: "";
}

.icon-reddit-alien:before {
  content: "";
}

.icon-edge:before {
  content: "";
}

.icon-usb:before {
  content: "";
}

.icon-bluetooth:before {
  content: "";
}

.icon-bluetooth-b:before {
  content: "";
}

.icon-assistive-listening-systems:before {
  content: "";
}

.icon-snapchat:before {
  content: "";
}

.icon-snapchat-ghost:before {
  content: "";
}

.icon-snapchat-square:before {
  content: "";
}

.icon-handshake-o:before {
  content: "";
}

.icon-envelope-open:before {
  content: "";
}

.icon-envelope-open-o:before {
  content: "";
}

.icon-user-circle:before {
  content: "";
}

.icon-user-circle-o:before {
  content: "";
}

.icon-user-o:before {
  content: "";
}

.icon-id-card-o:before {
  content: "";
}

.icon-telegram:before {
  content: "";
}

.icon-thermometer:before {
  content: "";
}

.icon-thermometer-2:before {
  content: "";
}

.icon-thermometer-0:before {
  content: "";
}

.icon-window-restore:before {
  content: "";
}

.icon-window-close:before {
  content: "";
}

.icon-window-close-o:before {
  content: "";
}

.icon-github-squared:before {
  content: "";
}

.icon-twitter-squared:before {
  content: "";
}

.icon-facebook-squared:before {
  content: "";
}

.icon-linkedin-squared:before {
  content: "";
}

@font-face {
  font-family: FoundersGrotesk-Regular;
  src: url("FoundersGrotesk-Regular.9c6b2295.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: FoundersGrotesk-Medium;
  src: url("FoundersGrotesk-Medium.77fe3100.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: FoundersGrotesk-Bold;
  src: url("FoundersGrotesk-Bold.65053998.otf") format("opentype");
  font-weight: bold;
  font-style: bold;
}

strong, b {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6, h7 {
  font-family: var(--font-family-primary);
}

p {
  font-family: var(--font-family-secondary);
  padding-bottom: 1.5rem;
  font-size: 1.125rem;
  line-height: 2rem;
}

.main-container--blue-bg {
  background-color: var(--color-blue-400);
  color: #fff;
}

.text ul li, .text ol li {
  font-family: var(--font-family-secondary);
  -webkit-padding-start: 6px;
  padding-inline-start: 6px;
  font-size: 1.125rem;
  line-height: 2rem;
}

a, p a {
  color: var(--color-blue-200);
}

.text ul li::marker {
  color: var(--color-blue-400);
}

.button .button--text {
  color: #0077cd;
}

.button--text-chevron-container {
  margin-bottom: 4px;
}

.divider--gray {
  background-image: linear-gradient(60deg, #9ac153, #e2dc7c);
  max-width: 100%;
  height: 2px;
}

.header__topbar {
  background-color: var(--color-white);
}

.header {
  font-size: 1.1rem;
  font-weight: normal;
}

.header .icon-inner {
  padding-top: 0;
}

.header__navbar ion-icon {
  margin-left: 20px;
  padding-top: 0;
}

.nei-footer__wrapper {
  max-width: 1600px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.header__search-button span {
  display: none;
}

.header__search-container {
  border-left: 1px solid #9a9a9a;
  min-width: 2.6875rem;
  margin-right: 1.5rem;
  display: none;
}

.nei-navigation ul.cmp-navigation__group {
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
}

footer {
  background-image: url("resources/images/footer-bg.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: auto;
}

.pre-footer {
  background-color: #205098;
}

.pre-footer__content {
  width: 70%;
}

.nei-footer .button--text {
  color: #fff;
}

.nei-footer__image {
  width: auto;
  max-height: none;
}

.pre-footer__title {
  font-weight: normal;
}

.pre-footer__line-bottom {
  border-bottom: none;
}

.btn-primary {
  letter-spacing: .265px;
  background: #205098;
  border: 0;
  border-radius: 36px;
  flex-shrink: 0;
  padding: 14px 36px;
  font-family: FoundersGrotesk-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-primary:hover {
  background-color: var(--color-navy-500);
  text-decoration: none;
}

#bestplace .btn-secondary {
  margin-left: 12px;
}

.btn-secondary {
  color: #161144;
  background: linear-gradient(#fff, #fff) padding-box padding-box, linear-gradient(60deg, #335597, #4295d5, #72ba86, #9ac153) border-box;
  border: 3px solid #0000;
  border-radius: 36px;
  padding: 10px 36px;
  display: inline-block;
}

.btn-secondary:hover, .btn-secondary button:active {
  color: #205098;
  background: linear-gradient(#fff, #fff) padding-box padding-box, linear-gradient(60deg, #335597, #4295d5, #72ba86, #9ac153) border-box;
}

.bi-arrow-right {
  margin-top: 14px;
  display: block;
}

#thumbCarousel {
  display: flex;
}

.product-slider {
  padding: 0;
}

.product-slider #carousel {
  border: 0 solid #1089c0;
  margin: 0;
}

.product-slider #thumbcarousel {
  margin: -120px 0 0;
  padding: 0 45px;
}

.product-slider #thumbcarousel .item {
  text-align: left;
}

.product-slider #thumbcarousel .item .thumb {
  vertical-align: middle;
  cursor: pointer;
  color: #111;
  background-color: #fff;
  border: 0 solid #cecece;
  border-bottom: 12px solid #f4f6f9;
  flex-direction: row;
  align-items: start;
  height: auto;
  margin: 0;
  padding: 30px 20px;
  display: flex;
}

.img-gradient {
  display: inline-block;
  position: relative;
}

.item-darkoverlay:after {
  content: "";
  z-index: 1;
  background: linear-gradient(0deg, #00000096 0%, #fff0 100%);
  display: block;
  position: absolute;
  inset: 0;
}

.carousel-caption {
  z-index: 2;
}

.carousel-inner > .item > .img-gradient:after {
  content: "";
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#002f4b", endColorstr= "#00000000", GradientType= 0);
  background: linear-gradient(#002f4b80 0%, #dc422580 100%);
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.img-gradient img {
  display: block;
}

.product-slider #thumbcarousel .item p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.product-slider #thumbcarousel .item h5 {
  font-family: FoundersGrotesk-Regular;
  font-size: 28px;
  font-weight: 400;
  line-height: 18px;
}

.product-slider .carousel-caption h3 {
  max-width: 82%;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
}

.product-slider .carousel-caption p {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

#thumbCarousel .divider {
  background: #205098;
  width: 35px;
  height: 3px;
  margin: 23px 0 14px;
  padding: 0;
}

#thumbCarousel .active .divider, #thumbCarousel .divider:hover {
  background: #48b9d6;
  width: 35px;
  height: 3px;
  margin: 23px 0 14px;
  padding: 0;
}

.product-slider #thumbcarousel .item .thumb:hover {
  color: #fff;
  background: #112055 url("resources/images/gradient-border.png") bottom / contain no-repeat;
  border: 0;
  padding-bottom: 42px;
}

.product-slider #thumbcarousel .item img {
  border-radius: 50%;
  width: 100%;
}

.product-slider #thumbcarousel .item .thumb.active {
  color: #fff;
  background: #112055 url("resources/images/gradient-border.png") bottom / contain no-repeat;
  border: 0;
  padding-bottom: 42px;
}

.product-slider .item img {
  width: 100%;
  height: auto;
}

.carousel-control {
  color: #0284b8;
  text-align: center;
  text-shadow: none;
  width: 30px;
  height: 30px;
  font-size: 30px;
  line-height: 20px;
  top: 23%;
}

.carousel-control:hover, .carousel-control:focus, .carousel-control:active {
  color: #333;
}

.carousel-caption, .carousel-control .fa {
  font: 30px / 26px FontAwesome;
}

.product-slider .carousel-caption {
  text-align: left;
  bottom: 130px;
  left: 6%;
}

.carousel-control {
  background-color: #0000;
  width: auto;
  font-size: 20px;
  position: absolute;
  top: 30%;
  bottom: auto;
  left: 0;
}

.carousel-control.right, .carousel-control.left {
  background-color: #0000;
  background-image: none;
}

.text-and-image__image img {
  max-width: none;
}

.info-area {
  align-self: center;
}

.insights-card__image-container--small {
  min-height: auto;
}

@media (width <= 1200px) {
  .product-slider #thumbcarousel .item .thumb, #thumbCarousel {
    flex-flow: wrap;
    width: 100%;
  }

  .product-slider #thumbcarousel {
    margin: 0;
    padding: 0 !important;
  }

  .product-slider #thumbcarousel .item img {
    width: 100%;
  }

  .product-slider .carousel-caption {
    position: initial;
    text-align: center;
    background: #112055;
  }

  #bestplace {
    flex-direction: column-reverse;
    background: url("resources/images/bg-arrow-gray-mobile.png") 100% 0 no-repeat !important;
    padding-top: 25px !important;
  }

  #bestplace .bestplace_content {
    padding: 20px !important;
  }

  #bestplace .eyebrow {
    font-size: 12px !important;
  }

  #bestplace .btn-secondary {
    margin: 10px 0 !important;
  }

  #stats-card-row {
    flex-flow: column;
    background: url("resources/images/stats-card-row-bg-mobile.png") 100% 0 / cover no-repeat !important;
    padding: 40px !important;
  }

  #stats-card-row dt {
    border-bottom: 3px solid !important;
  }

  #stats-card-row div {
    padding: 0 !important;
  }

  #stats-card-row dl {
    padding-bottom: 20px;
  }

  #stats-card-row dl dd {
    font-size: 14px;
  }

  .pre-footer {
    background-color: #205098;
  }

  .pre-footer__content {
    width: 100%;
  }

  .nei-navigation .cmp-navigation__group .cmp-navigation__item--level-0 > a {
    line-height: 2rem;
  }

  p {
    padding-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.625rem;
  }
}

#bestplace {
  background: url("resources/images/bg-arrow-gray.png") 100% 0 no-repeat;
  padding-top: 56px;
  display: flex;
}

#bestplace div {
  padding: 0;
}

#bestplace img {
  width: 100%;
  height: auto;
  display: flex;
}

#bestplace .bestplace_content {
  padding: 60px;
}

#bestplace p {
  padding: 25px 0;
  line-height: 28px;
}

#bestplace .eyebrow {
  color: #205098;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 18px;
}

#stats-card-row {
  color: #fff;
  background: url("resources/images/stats-card-row-bg.png") 100% 0 / cover no-repeat;
  padding: 86px;
  display: flex;
}

#stats-card-row dt {
  border-bottom: 12px solid #0000;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 43px;
  font-weight: normal;
  line-height: 45px;
}

#stats-card-row dt.title-g1 {
  border-image: linear-gradient(60deg, #335597, #4295d5, #72ba86, #9ac153);
  border-image-slice: 1;
}

#stats-card-row dt.title-g2 {
  border-image: url("resources/images/gradient-divider-2.png");
  border-image-slice: 1;
}

#stats-card-row dt.title-g3 {
  border-image: url("resources/images/gradient-divider-3.png");
  border-image-slice: 1;
}

#stats-card-row dt.title-g4 {
  border-image: url("resources/images/gradient-divider-4.png");
  border-image-slice: 1;
}

#stats-card-row dd {
  font-size: 20px;
}

#stats-card-row div {
  box-sizing: border-box;
  padding: 0 35px;
}

#stats-card-row div:last-child {
  padding-right: 0;
}

#stats-card-row div:first-child {
  padding-left: 0;
}

.nei-navigation .cmp-navigation .cmp-navigation__item--level-0.show-mega a.hover-bar:before, .nei-navigation .cmp-navigation .cmp-navigation__item--level-0.cmp-navigation__item--active a.hover-bar:before {
  border-bottom: border-bottom;
  border-image: linear-gradient(60deg, #335597, #4295d5, #72ba86, #9ac153) 1;
  top: 2.5rem;
  right: 0;
}

.nei-navigation .cmp-navigation__item--level-0 > a {
  margin: 0 5px;
  padding: 0 10px;
}

.nei-navigation .cmp-navigation__item--level-0 a.hover-bar:before {
  border-top: 0;
  transition: none;
}

.icon-angle-down:before {
  color: #0077cd;
}

.group_logo span {
  background: url("resources/images/aviso-logo.png") 0 0 / cover no-repeat;
  width: 11.3rem;
  height: 2.6rem;
}

.icon-nei-logo:before {
  content: none;
}

.btn--secondary {
  box-sizing: border-box;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner > .item {
  transition: left .6s ease-in-out;
  display: none;
  position: relative;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  line-height: 1;
}

@media (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    backface-visibility: hidden;
    perspective: 1000px;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  }

  .carousel-inner > .item.next, .carousel-inner > .item.active.right {
    left: 0;
    transform: translate3d(100%, 0, 0);
  }

  .carousel-inner > .item.prev, .carousel-inner > .item.active.left {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right, .carousel-inner > .item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
  width: 100%;
  position: absolute;
  top: 0;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px #0009;
  filter: alpha(opacity= 50);
  opacity: .5;
  background-color: #0000;
  width: 15%;
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.carousel-control.left {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#80000000", endColorstr= "#00000000", GradientType= 1);
  background-image: linear-gradient(to right, #00000080 0%, #0000 100%);
  background-repeat: repeat-x;
}

.carousel-control.right {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#80000000", GradientType= 1);
  background-image: linear-gradient(to right, #0000 0%, #00000080 100%);
  background-repeat: repeat-x;
  left: auto;
  right: 0;
}

.carousel-control:hover, .carousel-control:focus {
  color: #fff;
  filter: alpha(opacity= 90);
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
  z-index: 5;
  margin-top: -10px;
  display: inline-block;
  position: absolute;
  top: 50%;
}

.carousel-control .icon-prev, .carousel-control .glyphicon-chevron-left {
  margin-left: -10px;
  left: 50%;
}

.carousel-control .icon-next, .carousel-control .glyphicon-chevron-right {
  margin-right: -10px;
  right: 50%;
}

.carousel-control .icon-prev, .carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: "‹";
}

.carousel-control .icon-next:before {
  content: "›";
}

.carousel-indicators {
  z-index: 15;
  text-align: center;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

.carousel-indicators li {
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9 ;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin: 1px;
  display: inline-block;
}

.carousel-indicators .active {
  background-color: #fff;
  width: 12px;
  height: 12px;
  margin: 0;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px #0009;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (width >= 768px) {
  .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-prev, .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    padding-bottom: 30px;
    left: 20%;
    right: 20%;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}

.clearfix:before, .clearfix:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after {
  content: " ";
  display: table;
}

.clearfix:after, .container:after, .container-fluid:after, .row:after {
  clear: both;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important;
}

@media (width <= 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs, td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm, td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md, td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (width >= 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg, td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (width <= 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print, td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

.button {
  font-family: var(--font-family-secondary);
}

:root {
  --color-navy-500: #112055;
  --color-navy-400: #345d6f;
  --color-navy-300: #678593;
  --color-navy-200: #99aeb7;
  --color-navy-100: #ccd6db;
  --color-blue-800: #122055;
  --color-blue-700: #c0ddf3;
  --color-blue-600: #0077cd;
  --color-blue-500: #3c8ed5;
  --color-blue-400: #205098;
  --color-blue-300: #0056b3;
  --color-blue-200: #0077cd;
  --color-blue-100: #e6f1fa;
  --color-turkish-300: #0080a6;
  --color-turkish-200: #99ccdb;
  --color-turkish-100: #e6f2f6;
  --color-jade-300: #112055;
  --color-jade-200: #99cdc5;
  --color-jade-100: #cce6e2;
  --color-red-500: #ce0019;
  --color-green-300: #85be00;
  --color-green-200: #cee7e3;
  --color-green-100: #e6f3f1;
  --color-purple-200: #5948ad;
  --color-purple-100: #6f42c1;
  --color-yellow: #ffc107;
  --color-cyan: #17a2b8;
  --color-white: #fff;
  --color-gray-800: #212;
  --color-gray-700: #e6ebed;
  --color-gray-600: #212121;
  --color-gray-500: #555960;
  --color-gray-400: #84878c;
  --color-gray-300: #efefef;
  --color-gray-200: #e2e3e5;
  --color-gray-100: #fafafa;
  --color-slate-200: #dddedf;
  --color-slate-100: #eeeeef;
  --color-light: #f8f9fa;
  --color-dark: #343a40;
  --color-black: #000;
  --color-neutral-on-background: #352c39;
  --font-family-primary: "FoundersGrotesk-Regular", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  --font-family-secondary: "din2014-regular", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  --font-weight-bold: 700;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-demi: 600;
}

/*# sourceMappingURL=theme.css.map */
