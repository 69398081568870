@font-face {
  font-family: 'nei_icons';
  src: url('../resources/icons/nei_icons.eot?v=0.22');
  src: url('../resources/icons/nei_icons.eot?v=0.22#iefix') format('embedded-opentype'),
  url('../resources/icons/nei_icons.woff2?v=0.22') format('woff2'),
  url('../resources/icons/nei_icons.woff?v=0.22') format('woff'),
  url('../resources/icons/nei_icons.ttf?v=0.22') format('truetype'),
  url('../resources/icons/nei_icons.svg?v=0.22#nei_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "nei_icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-fossil_fuel_free:before { content: '\e800'; } /* '' */
.icon-impact_thematic:before { content: '\e801'; } /* '' */
.icon-active_ownership:before { content: '\e802'; } /* '' */
.icon-esg_integration:before { content: '\e803'; } /* '' */
.icon-exclusionary_screens:before { content: '\e804'; } /* '' */
.icon-mail:before { content: '\e805'; } /* '' */
.icon-heart:before { content: '\e806'; } /* '' */
.icon-heart-empty:before { content: '\e807'; } /* '' */
.icon-star:before { content: '\e808'; } /* '' */
.icon-star-empty:before { content: '\e809'; } /* '' */
.icon-star-half:before { content: '\e80a'; } /* '' */
.icon-user:before { content: '\e80b'; } /* '' */
.icon-users:before { content: '\e80c'; } /* '' */
.icon-picture:before { content: '\e80d'; } /* '' */
.icon-camera:before { content: '\e80e'; } /* '' */
.icon-th-large:before { content: '\e80f'; } /* '' */
.icon-th:before { content: '\e810'; } /* '' */
.icon-th-list:before { content: '\e811'; } /* '' */
.icon-ok:before { content: '\e812'; } /* '' */
.icon-download:before { content: '\e813'; } /* '' */
.icon-upload:before { content: '\e814'; } /* '' */
.icon-bookmark:before { content: '\e815'; } /* '' */
.icon-trash-empty:before { content: '\e816'; } /* '' */
.icon-doc:before { content: '\e817'; } /* '' */
.icon-folder-open:before { content: '\e818'; } /* '' */
.icon-folder:before { content: '\e819'; } /* '' */
.icon-link:before { content: '\e81a'; } /* '' */
.icon-info-circled:before { content: '\e81b'; } /* '' */
.icon-home:before { content: '\e81c'; } /* '' */
.icon-retweet:before { content: '\e81d'; } /* '' */
.icon-comment:before { content: '\e81e'; } /* '' */
.icon-chat:before { content: '\e81f'; } /* '' */
.icon-bell:before { content: '\e820'; } /* '' */
.icon-attention:before { content: '\e821'; } /* '' */
.icon-attention-circled:before { content: '\e822'; } /* '' */
.icon-flag:before { content: '\e823'; } /* '' */
.icon-tags:before { content: '\e824'; } /* '' */
.icon-tag:before { content: '\e825'; } /* '' */
.icon-eye-off:before { content: '\e826'; } /* '' */
.icon-eye:before { content: '\e827'; } /* '' */
.icon-lock-open:before { content: '\e828'; } /* '' */
.icon-lock:before { content: '\e829'; } /* '' */
.icon-attach:before { content: '\e82a'; } /* '' */
.icon-forward:before { content: '\e82b'; } /* '' */
.icon-phone:before { content: '\e82c'; } /* '' */
.icon-pencil:before { content: '\e82d'; } /* '' */
.icon-edit:before { content: '\e82e'; } /* '' */
.icon-print:before { content: '\e82f'; } /* '' */
.icon-down-circled2:before { content: '\e830'; } /* '' */
.icon-up-circled2:before { content: '\e831'; } /* '' */
.icon-down-dir:before { content: '\e832'; } /* '' */
.icon-up-dir:before { content: '\e833'; } /* '' */
.icon-left-dir:before { content: '\e834'; } /* '' */
.icon-right-dir:before { content: '\e835'; } /* '' */
.icon-down-open:before { content: '\e836'; } /* '' */
.icon-left-open:before { content: '\e837'; } /* '' */
.icon-right-open:before { content: '\e838'; } /* '' */
.icon-up-open:before { content: '\e839'; } /* '' */
.icon-inbox:before { content: '\e83a'; } /* '' */
.icon-globe:before { content: '\e83b'; } /* '' */
.icon-award:before { content: '\e83c'; } /* '' */
.icon-to-start-alt:before { content: '\e83d'; } /* '' */
.icon-fast-fw:before { content: '\e83e'; } /* '' */
.icon-fast-bw:before { content: '\e83f'; } /* '' */
.icon-play:before { content: '\e840'; } /* '' */
.icon-stop:before { content: '\e841'; } /* '' */
.icon-pause:before { content: '\e842'; } /* '' */
.icon-to-end:before { content: '\e843'; } /* '' */
.icon-to-end-alt:before { content: '\e844'; } /* '' */
.icon-to-start:before { content: '\e845'; } /* '' */
.icon-text-height:before { content: '\e846'; } /* '' */
.icon-text-width:before { content: '\e847'; } /* '' */
.icon-align-left:before { content: '\e848'; } /* '' */
.icon-align-center:before { content: '\e849'; } /* '' */
.icon-align-right:before { content: '\e84a'; } /* '' */
.icon-align-justify:before { content: '\e84b'; } /* '' */
.icon-list:before { content: '\e84c'; } /* '' */
.icon-indent-left:before { content: '\e84d'; } /* '' */
.icon-indent-right:before { content: '\e84e'; } /* '' */
.icon-cloud:before { content: '\e84f'; } /* '' */
.icon-flash:before { content: '\e850'; } /* '' */
.icon-umbrella:before { content: '\e851'; } /* '' */
.icon-scissors:before { content: '\e852'; } /* '' */
.icon-chart-bar:before { content: '\e853'; } /* '' */
.icon-credit-card:before { content: '\e854'; } /* '' */
.icon-floppy:before { content: '\e855'; } /* '' */
.icon-megaphone:before { content: '\e856'; } /* '' */
.icon-search:before { content: '\e857'; } /* '' */
.icon-cog:before { content: '\e858'; } /* '' */
.icon-cog-alt:before { content: '\e859'; } /* '' */
.icon-calendar:before { content: '\e85a'; } /* '' */
.icon-volume-off:before { content: '\e85b'; } /* '' */
.icon-volume-down:before { content: '\e85c'; } /* '' */
.icon-volume-up:before { content: '\e85d'; } /* '' */
.icon-block:before { content: '\e85e'; } /* '' */
.icon-resize-full:before { content: '\e85f'; } /* '' */
.icon-resize-small:before { content: '\e860'; } /* '' */
.icon-resize-vertical:before { content: '\e861'; } /* '' */
.icon-resize-horizontal:before { content: '\e862'; } /* '' */
.icon-zoom-in:before { content: '\e863'; } /* '' */
.icon-zoom-out:before { content: '\e864'; } /* '' */
.icon-minus-circled:before { content: '\e865'; } /* '' */
.icon-plus-circled:before { content: '\e866'; } /* '' */
.icon-cancel:before { content: '\e867'; } /* '' */
.icon-cancel-circled:before { content: '\e868'; } /* '' */
.icon-cancel-circled2:before { content: '\e869'; } /* '' */
.icon-ok-circled:before { content: '\e86a'; } /* '' */
.icon-ok-circled2:before { content: '\e86b'; } /* '' */
.icon-truck:before { content: '\e86c'; } /* '' */
.icon-list-alt:before { content: '\e86d'; } /* '' */
.icon-adjust:before { content: '\e86e'; } /* '' */
.icon-check:before { content: '\e86f'; } /* '' */
.icon-attention-1:before { content: '\e870'; } /* '' */
.icon-cw:before { content: '\e871'; } /* '' */
.icon-ccw:before { content: '\e872'; } /* '' */
.icon-arrows-cw:before { content: '\e873'; } /* '' */
.icon-heart-1:before { content: '\e874'; } /* '' */
.icon-heart-filled:before { content: '\e875'; } /* '' */
.icon-star-1:before { content: '\e876'; } /* '' */
.icon-star-filled:before { content: '\e877'; } /* '' */
.icon-off:before { content: '\e878'; } /* '' */
.icon-leaf:before { content: '\e879'; } /* '' */
.icon-flight:before { content: '\e87a'; } /* '' */
.icon-down-big:before { content: '\e87b'; } /* '' */
.icon-left-big:before { content: '\e87c'; } /* '' */
.icon-right-big:before { content: '\e87d'; } /* '' */
.icon-up-big:before { content: '\e87e'; } /* '' */
.icon-right-hand:before { content: '\e87f'; } /* '' */
.icon-left-hand:before { content: '\e880'; } /* '' */
.icon-up-hand:before { content: '\e881'; } /* '' */
.icon-down-hand:before { content: '\e882'; } /* '' */
.icon-basket:before { content: '\e883'; } /* '' */
.icon-login:before { content: '\e884'; } /* '' */
.icon-logout:before { content: '\e885'; } /* '' */
.icon-headphones:before { content: '\e886'; } /* '' */
.icon-thumbs-up:before { content: '\e887'; } /* '' */
.icon-thumbs-down:before { content: '\e888'; } /* '' */
.icon-location:before { content: '\e889'; } /* '' */
.icon-wrench:before { content: '\e88a'; } /* '' */
.icon-help-circled:before { content: '\e88b'; } /* '' */
.icon-briefcase:before { content: '\e88c'; } /* '' */
.icon-road:before { content: '\e88d'; } /* '' */
.icon-book:before { content: '\e88e'; } /* '' */
.icon-barcode:before { content: '\e88f'; } /* '' */
.icon-down-bold:before { content: '\e890'; } /* '' */
.icon-left-bold:before { content: '\e891'; } /* '' */
.icon-right-bold:before { content: '\e892'; } /* '' */
.icon-up-bold:before { content: '\e893'; } /* '' */
.icon-ccw-1:before { content: '\e894'; } /* '' */
.icon-cw-1:before { content: '\e895'; } /* '' */
.icon-arrows-ccw:before { content: '\e896'; } /* '' */
.icon-level-down:before { content: '\e897'; } /* '' */
.icon-level-up:before { content: '\e898'; } /* '' */
.icon-shuffle:before { content: '\e899'; } /* '' */
.icon-loop:before { content: '\e89a'; } /* '' */
.icon-user-1:before { content: '\e89b'; } /* '' */
.icon-users-1:before { content: '\e89c'; } /* '' */
.icon-user-add:before { content: '\e89d'; } /* '' */
.icon-user-outline:before { content: '\e89e'; } /* '' */
.icon-user-2:before { content: '\e89f'; } /* '' */
.icon-users-outline:before { content: '\e8a0'; } /* '' */
.icon-users-2:before { content: '\e8a1'; } /* '' */
.icon-user-add-outline:before { content: '\e8a2'; } /* '' */
.icon-user-add-1:before { content: '\e8a3'; } /* '' */
.icon-user-delete-outline:before { content: '\e8a4'; } /* '' */
.icon-user-delete:before { content: '\e8a5'; } /* '' */
.icon-nei-logo:before { content: '\e8a6'; } /* '' */
.icon-spin3:before { content: '\e8a7'; } /* '' */
.icon-spin4:before { content: '\e8a8'; } /* '' */
.icon-spin6:before { content: '\e8a9'; } /* '' */
.icon-crown:before { content: '\e8aa'; } /* '' */
.icon-crown-plus:before { content: '\e8ab'; } /* '' */
.icon-crown-minus:before { content: '\e8ac'; } /* '' */
.icon-marquee:before { content: '\e8ad'; } /* '' */
.icon-result:before { content: '\e8ae'; } /* '' */
.icon-nei_chevron:before { content: '\e98f'; } /* '' */
.icon-quote_mark:before { content: '\e990'; } /* '' */
.icon-ri_icon_exclusionary_screens-converted:before { content: '\e991'; } /* '' */
.icon-ri_icon_fossil_fuel_-free-converted:before { content: '\e992'; } /* '' */
.icon-ri_icon_impact_thematic-converted:before { content: '\e993'; } /* '' */
.icon-ri_icon_active_ownership-converted:before { content: '\e994'; } /* '' */
.icon-ri_icon_esg_integration-converted:before { content: '\e995'; } /* '' */
.icon-move:before { content: '\f047'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-github-circled:before { content: '\f09b'; } /* '' */
.icon-hdd:before { content: '\f0a0'; } /* '' */
.icon-left-circled:before { content: '\f0a8'; } /* '' */
.icon-right-circled:before { content: '\f0a9'; } /* '' */
.icon-up-circled:before { content: '\f0aa'; } /* '' */
.icon-down-circled:before { content: '\f0ab'; } /* '' */
.icon-tasks:before { content: '\f0ae'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-resize-full-alt:before { content: '\f0b2'; } /* '' */
.icon-beaker:before { content: '\f0c3'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-blank:before { content: '\f0c8'; } /* '' */
.icon-list-bullet:before { content: '\f0ca'; } /* '' */
.icon-list-numbered:before { content: '\f0cb'; } /* '' */
.icon-strike:before { content: '\f0cc'; } /* '' */
.icon-underline:before { content: '\f0cd'; } /* '' */
.icon-table:before { content: '\f0ce'; } /* '' */
.icon-pinterest-circled:before { content: '\f0d2'; } /* '' */
.icon-pinterest-squared:before { content: '\f0d3'; } /* '' */
.icon-money:before { content: '\f0d6'; } /* '' */
.icon-columns:before { content: '\f0db'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-gauge:before { content: '\f0e4'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-suitcase:before { content: '\f0f2'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-hospital:before { content: '\f0f8'; } /* '' */
.icon-ambulance:before { content: '\f0f9'; } /* '' */
.icon-medkit:before { content: '\f0fa'; } /* '' */
.icon-fighter-jet:before { content: '\f0fb'; } /* '' */
.icon-h-sigh:before { content: '\f0fd'; } /* '' */
.icon-plus-squared:before { content: '\f0fe'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-desktop:before { content: '\f108'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-spinner:before { content: '\f110'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-reply:before { content: '\f112'; } /* '' */
.icon-github:before { content: '\f113'; } /* '' */
.icon-folder-empty:before { content: '\f114'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-keyboard:before { content: '\f11c'; } /* '' */
.icon-flag-empty:before { content: '\f11d'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-reply-all:before { content: '\f122'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-crop:before { content: '\f125'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-superscript:before { content: '\f12b'; } /* '' */
.icon-subscript:before { content: '\f12c'; } /* '' */
.icon-mic:before { content: '\f130'; } /* '' */
.icon-mute:before { content: '\f131'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-angle-circled-left:before { content: '\f137'; } /* '' */
.icon-angle-circled-right:before { content: '\f138'; } /* '' */
.icon-angle-circled-up:before { content: '\f139'; } /* '' */
.icon-angle-circled-down:before { content: '\f13a'; } /* '' */
.icon-html5:before { content: '\f13b'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-rss-squared:before { content: '\f143'; } /* '' */
.icon-ticket:before { content: '\f145'; } /* '' */
.icon-minus-squared:before { content: '\f146'; } /* '' */
.icon-minus-squared-alt:before { content: '\f147'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-pencil-squared:before { content: '\f14b'; } /* '' */
.icon-pound:before { content: '\f154'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
.icon-rupee:before { content: '\f156'; } /* '' */
.icon-yen:before { content: '\f157'; } /* '' */
.icon-rouble:before { content: '\f158'; } /* '' */
.icon-bitcoin:before { content: '\f15a'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-dropbox:before { content: '\f16b'; } /* '' */
.icon-tumblr:before { content: '\f173'; } /* '' */
.icon-tumblr-squared:before { content: '\f174'; } /* '' */
.icon-down:before { content: '\f175'; } /* '' */
.icon-up:before { content: '\f176'; } /* '' */
.icon-left:before { content: '\f177'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-apple:before { content: '\f179'; } /* '' */
.icon-android:before { content: '\f17b'; } /* '' */
.icon-skype:before { content: '\f17e'; } /* '' */
.icon-sun:before { content: '\f185'; } /* '' */
.icon-moon:before { content: '\f186'; } /* '' */
.icon-right-circled2:before { content: '\f18e'; } /* '' */
.icon-left-circled2:before { content: '\f190'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-wheelchair:before { content: '\f193'; } /* '' */
.icon-vimeo-squared:before { content: '\f194'; } /* '' */
.icon-plus-squared-alt:before { content: '\f196'; } /* '' */
.icon-space-shuttle:before { content: '\f197'; } /* '' */
.icon-slack:before { content: '\f198'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-reddit:before { content: '\f1a1'; } /* '' */
.icon-digg:before { content: '\f1a6'; } /* '' */
.icon-drupal:before { content: '\f1a9'; } /* '' */
.icon-language:before { content: '\f1ab'; } /* '' */
.icon-building-filled:before { content: '\f1ad'; } /* '' */
.icon-cab:before { content: '\f1b9'; } /* '' */
.icon-taxi:before { content: '\f1ba'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-jsfiddle:before { content: '\f1cc'; } /* '' */
.icon-rebel:before { content: '\f1d0'; } /* '' */
.icon-empire:before { content: '\f1d1'; } /* '' */
.icon-git-squared:before { content: '\f1d2'; } /* '' */
.icon-git:before { content: '\f1d3'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-circle-thin:before { content: '\f1db'; } /* '' */
.icon-header:before { content: '\f1dc'; } /* '' */
.icon-paragraph:before { content: '\f1dd'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-newspaper:before { content: '\f1ea'; } /* '' */
.icon-wifi:before { content: '\f1eb'; } /* '' */
.icon-calc:before { content: '\f1ec'; } /* '' */
.icon-paypal:before { content: '\f1ed'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-discover:before { content: '\f1f2'; } /* '' */
.icon-cc-amex:before { content: '\f1f3'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-cc-stripe:before { content: '\f1f5'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-bell-off-empty:before { content: '\f1f7'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-copyright:before { content: '\f1f9'; } /* '' */
.icon-at:before { content: '\f1fa'; } /* '' */
.icon-eyedropper:before { content: '\f1fb'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-pie:before { content: '\f200'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-bicycle:before { content: '\f206'; } /* '' */
.icon-bus:before { content: '\f207'; } /* '' */
.icon-cart-plus:before { content: '\f217'; } /* '' */
.icon-cart-arrow-down:before { content: '\f218'; } /* '' */
.icon-ship:before { content: '\f21a'; } /* '' */
.icon-motorcycle:before { content: '\f21c'; } /* '' */
.icon-neuter:before { content: '\f22c'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-bed:before { content: '\f236'; } /* '' */
.icon-train:before { content: '\f238'; } /* '' */
.icon-subway:before { content: '\f239'; } /* '' */
.icon-battery-4:before { content: '\f240'; } /* '' */
.icon-battery-3:before { content: '\f241'; } /* '' */
.icon-battery-2:before { content: '\f242'; } /* '' */
.icon-battery-1:before { content: '\f243'; } /* '' */
.icon-i-cursor:before { content: '\f246'; } /* '' */
.icon-sticky-note:before { content: '\f249'; } /* '' */
.icon-sticky-note-o:before { content: '\f24a'; } /* '' */
.icon-cc-jcb:before { content: '\f24b'; } /* '' */
.icon-cc-diners-club:before { content: '\f24c'; } /* '' */
.icon-clone:before { content: '\f24d'; } /* '' */
.icon-hand-pointer-o:before { content: '\f25a'; } /* '' */
.icon-chrome:before { content: '\f268'; } /* '' */
.icon-firefox:before { content: '\f269'; } /* '' */
.icon-internet-explorer:before { content: '\f26b'; } /* '' */
.icon-calendar-times-o:before { content: '\f273'; } /* '' */
.icon-calendar-check-o:before { content: '\f274'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-map:before { content: '\f279'; } /* '' */
.icon-commenting:before { content: '\f27a'; } /* '' */
.icon-commenting-o:before { content: '\f27b'; } /* '' */
.icon-reddit-alien:before { content: '\f281'; } /* '' */
.icon-edge:before { content: '\f282'; } /* '' */
.icon-usb:before { content: '\f287'; } /* '' */
.icon-bluetooth:before { content: '\f293'; } /* '' */
.icon-bluetooth-b:before { content: '\f294'; } /* '' */
.icon-assistive-listening-systems:before { content: '\f2a2'; } /* '' */
.icon-snapchat:before { content: '\f2ab'; } /* '' */
.icon-snapchat-ghost:before { content: '\f2ac'; } /* '' */
.icon-snapchat-square:before { content: '\f2ad'; } /* '' */
.icon-handshake-o:before { content: '\f2b5'; } /* '' */
.icon-envelope-open:before { content: '\f2b6'; } /* '' */
.icon-envelope-open-o:before { content: '\f2b7'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */
.icon-id-card-o:before { content: '\f2c3'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
.icon-thermometer:before { content: '\f2c7'; } /* '' */
.icon-thermometer-2:before { content: '\f2c9'; } /* '' */
.icon-thermometer-0:before { content: '\f2cb'; } /* '' */
.icon-window-restore:before { content: '\f2d2'; } /* '' */
.icon-window-close:before { content: '\f2d3'; } /* '' */
.icon-window-close-o:before { content: '\f2d4'; } /* '' */
.icon-github-squared:before { content: '\f300'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */
