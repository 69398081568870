/* global */
h1, h2, h3, h4, h5, h6, h7 {
	font-family: var(--font-family-primary);
}
p {
	font-family: var(--font-family-secondary);
	font-size: 1.125rem;
	line-height: 2rem;
	padding-bottom: 1.5rem;
}
.main-container--blue-bg {
	background-color: var(--color-blue-400);
	color: #fff;
}
.text ul li, .text ol li {
	font-family: var(--font-family-secondary);
	font-size: 1.125rem;
	line-height: 2rem;
	padding-inline-start : 6px;
}
a, p a {
	color: var(--color-blue-200);
}
.text ul li::marker {
	color: var(--color-blue-400);
}

.button .button--text {
	color: #0077CD;
}
.button--text-chevron-container {
	margin-bottom :4px;
}
.divider--gray {
	background-image: linear-gradient(60deg,#9ac153, #e2dc7c);
    height: 2px;
	max-width: 100%;
}
.header__topbar {
	background-color: var(--color-white);
}
.header {
	font-size:1.1rem;
	font-weight: normal;
}
.header .icon-inner {
	padding-top: 0px;
}
.header__navbar ion-icon {
	margin-left:20px;
	padding-top:0px;
}
.nei-footer__wrapper {
	max-width:1600px;
	padding-left:2.5rem;
	padding-right:2.5rem;
}
 
.header__search-button span {
		display:none;
}

.header {
	&__search-container {
		margin-right:1.5rem;
		border-left: 1px solid #9A9A9A;
		min-width:2.6875rem;
		display: none; /* hide search */ 
	}

}
.nei-navigation ul.cmp-navigation {
	&__group {
		justify-content: right;
	}
}
footer {
	background-image: url('../resources/images/backgrounds/footer-bg.png');
	background-position: top right; background-repeat: no-repeat; background-size:auto ;
}
.pre-footer {
	background-color: #205098;

	&__content {
		width: 70%;
	}
}
.nei-footer .button--text {
	color:#fff;
}

.nei-footer__image {
	width: auto;
	max-height: none;

}
.pre-footer__title {
	font-weight: normal;
}
.pre-footer__line-bottom {
	border-bottom: none;
}

.btn-primary {
	border-radius: 36px;
	background: #205098;
	border: 0px;
	flex-shrink: 0;
	padding : 14px 36px;
	font-family: "FoundersGrotesk-Regular";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.265px;
}
.btn-primary:hover {
	background-color:var(--color-navy-500);
	text-decoration:none;
}
 #bestplace .btn-secondary {
	 margin-left:12px;
 }

.btn-secondary {
	 background: linear-gradient(#fff, #fff) padding-box, linear-gradient(60deg, #335597, #4295d5, #72ba86, #9ac153) border-box;
      color: #161144;
      border: 3px solid transparent;
      border-radius: 36px;
      display: inline-block;
	  padding: 10px 36px;
}
.btn-secondary:hover,
.btn-secondary button:active {
	color: #205098;
	 background: linear-gradient(#fff, #fff) padding-box, linear-gradient(60deg, #335597, #4295d5, #72ba86, #9ac153) border-box;
}
.bi-arrow-right {
	display: block;
	margin-top:14px;
}
 /* Global ends */
 /* Aviso home Carousel slider */
 #thumbCarousel {
	 display: flex;
 }
 
 .product-slider { padding: 0px; }
.product-slider #carousel { border: 0px solid #1089c0; margin: 0;  }

.product-slider #thumbcarousel { margin: 12px 0 0; padding: 0 45px; margin-top:-120px;  }

.product-slider #thumbcarousel .item { text-align: left; }

.product-slider #thumbcarousel .item .thumb {
	border: 0px solid #cecece;  margin: 0; display: flex; flex-direction: row;
	 vertical-align: middle; cursor: pointer;
	 /* min-width: 498px; */ background-color:#fff; height: auto; color: #111111; 	padding :30px 20px; border-bottom: 12px solid #F4F6F9;
	 align-items: start;
}

.img-gradient{
  position:relative;
  display:inline-block;
}

.item-darkoverlay:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(0deg, rgba(0,0,0,0.5886729691876751) 0%, rgba(255,255,255,0) 100%);
	z-index: 1;
  }
  .carousel-caption {
	z-index: 2;
  }

.carousel-inner > .item > .img-gradient:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
	.img-gradient img{
  display:block;
}

.product-slider #thumbcarousel .item p {
	font-weight: 400;
	line-height: 24px;
	font-size: 14px;
}
.product-slider #thumbcarousel .item h5 {
	font-size: 28px;
	font-family: "FoundersGrotesk-Regular";
	font-weight: 400;
	line-height: 18px;
}

.product-slider .carousel-caption h3 {
	font-size: 64px;
	font-style: normal;
	font-weight: 400;
	line-height: 80px; /* 102.326% */
	max-width: 82%;
}


.product-slider .carousel-caption p {
	font-size: 32px;	
	font-style: normal;
	font-weight: 400;
	line-height: 36px; /* 121.875% */
}
#thumbCarousel .divider {
	width: 35px;
	height: 3px;
	background: #205098;
	padding:0px;
	margin: 23px 0px 14px 0px;
}
#thumbCarousel .active .divider,
#thumbCarousel .divider:hover {
	width: 35px;
	height: 3px;
	background: #48B9D6;
	padding:0px;
	margin: 23px 0px 14px 0px;
}

.product-slider #thumbcarousel .item .thumb:hover {
	background: #112055; color: #fff;
	border:0px; background-image: url('../resources/images/backgrounds/gradient-border.png'); background-position: center bottom; background-repeat: no-repeat; background-size: contain;
	padding-bottom: 42px;
}
.product-slider #thumbcarousel .item img {
	width : 100%;
	border-radius: 50%; 

}
.product-slider #thumbcarousel .item .thumb.active { background: #112055; color: #fff; border:0px;
	background-image: url('../resources/images/backgrounds/gradient-border.png'); background-position: center bottom;
	background-repeat: no-repeat; background-size: contain;
	padding-bottom: 42px;}

.product-slider .item img { width: 100%; height: auto;  }

.carousel-control { color: #0284b8; text-align: center; text-shadow: none; font-size: 30px; width: 30px; height: 30px; line-height: 20px; top: 23%; }

.carousel-control:hover, .carousel-control:focus, .carousel-control:active { color: #333; }

.carousel-caption, .carousel-control .fa { font: normal normal normal 30px/26px FontAwesome; }
.product-slider .carousel-caption {
	bottom :130px;
	text-align:left;
	left:6%;
}
.carousel-control { background-color: rgba(0, 0, 0, 0); bottom: auto; font-size: 20px; left: 0; position: absolute; top: 30%; width: auto; }
.carousel-control.right, .carousel-control.left { background-color: rgba(0, 0, 0, 0); background-image: none; }

/* text and image component */
.text-and-image__image img {
	max-width: none;
}
.info-area{
	align-self: center;
}
.insights-card__image-container--small {
	min-height: auto;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */

@media (max-width: 1200px) {
.product-slider #thumbcarousel .item .thumb , #thumbCarousel {
    flex-direction: row;
	flex-flow: row wrap;
	width:100%;
	
  }
.product-slider #thumbcarousel {
	  padding:0px!important;
	  margin:0px;
  }
  .product-slider #thumbcarousel .item img {
	width: 100%;
  }
  
  .product-slider .carousel-caption {
	  position : initial;
	  text-align: center;
	  background: #112055;
  }
  #bestplace  {
	 flex-direction: column-reverse;
	 padding-top: 25px!important;
	background:url('../resources/images/backgrounds/bg-arrow-gray-mobile.png')no-repeat right top!important;
  }
  #bestplace .bestplace_content {
	  padding:20px!important;
  }
  #bestplace .eyebrow {
	  font-size: 12px!important;
  }
   #bestplace .btn-secondary {
	   margin: 10px 0px!important; 
   }
   #stats-card-row {
	flex-flow:column;
	padding : 40px!important;
	background: url('../resources/images/backgrounds/stats-card-row-bg-mobile.png') no-repeat top right!important;
	background-size : cover!important;

   }
   #stats-card-row dt {
	   border-bottom: 3px solid!important;
   }
   #stats-card-row div{
	padding: 0px 0px!important;
	}
	#stats-card-row dl {
		padding-bottom: 20px;
	}
	#stats-card-row dl dd {
		font-size:14px;
	}

	.pre-footer {
		background-color: #205098;
		&__content {
			width: 100%;
		}
	}

	.nei-navigation .cmp-navigation__group .cmp-navigation__item--level-0>a {
		line-height : 2rem;
	}

	p {
		font-size: 1rem;
		line-height: 1.625rem;
		padding-bottom: 1.5rem;
	}
}
/* Resonsive ends /*

/* bestplace section start  */

#bestplace {
	display: flex;
	padding-top: 56px;
	background:url('../resources/images/backgrounds/bg-arrow-gray.png')no-repeat right top;
}
#bestplace div {
	padding:0px;
}
#bestplace img {
	width:100%;
	height:auto;
	display: flex;
}

#bestplace .bestplace_content {
	padding:60px;
}
#bestplace p {
	padding: 25px 0px;
	line-height: 28px;
}
 #bestplace .eyebrow {
	color: #205098;
	font-size: 18px;
	line-height: 18px;
	text-transform: uppercase;
	padding-bottom:10px;
}
/* bestplace section ends  */

/* stats-card section start  */
#stats-card-row {
	padding:86px;
	background: url('../resources/images/backgrounds/stats-card-row-bg.png') no-repeat top right;
	color:#fff;
	display:flex;
	background-size: cover;
	
}

#stats-card-row dt {
	padding-bottom:30px;
	border-bottom: 12px solid transparent;
	line-height: 45px;
	font-size: 43px;
	font-weight: normal;
	margin-bottom:30px;
	
}
#stats-card-row dt.title-g1 {
    border-image: linear-gradient(60deg, #335597, #4295d5, #72ba86, #9ac153);
    border-image-slice: 1;
}
#stats-card-row dt.title-g2 {
    border-image: url('../resources/images/backgrounds/gradient-divider-2.png');
    border-image-slice: 1;
}
#stats-card-row dt.title-g3 {
    border-image: url('../resources/images/backgrounds/gradient-divider-3.png');
    border-image-slice: 1;
}
#stats-card-row dt.title-g4 {
    border-image: url('../resources/images/backgrounds/gradient-divider-4.png');
    border-image-slice: 1;
}

#stats-card-row dd {
	font-size:20px;
}
#stats-card-row div{
	padding: 0px 35px;
	box-sizing: border-box; 
}
#stats-card-row div:last-child {
	padding-right:0px;
}
 #stats-card-row div:first-child {
	padding-left:0px; 
 }
 /* stats-card section start  */

// Navigation
.nei-navigation .cmp-navigation .cmp-navigation__item--level-0.show-mega a.hover-bar::before,
.nei-navigation .cmp-navigation .cmp-navigation__item--level-0.cmp-navigation__item--active a.hover-bar::before {
  top :2.5rem;
  border-image: linear-gradient(60deg,#335597,#4295d5,#72ba86,#9ac153) 1;
  border-bottom: border-bottom;
  right:0px;
}
.nei-navigation .cmp-navigation__item--level-0>a {
	margin:0px 5px;
	padding: 0px 10px;
}
.nei-navigation .cmp-navigation__item--level-0 a.hover-bar::before {
	transition :none;
	border-top :0px;
}
.icon-angle-down:before {
	color: #0077CD;
}
.group_logo span {
	background:url('../resources/images/aviso-logo.png') no-repeat;
	background-size:cover;
	background-position: 0px 0px;
	width: 11.3rem;
	height: 2.6rem;
}
.icon-nei-logo:before {
	content:none;
}

.btn--secondary {
	box-sizing: border-box;
}